<script>
import StripeElementsSplitted from '@shared/components/StripeElementsSplitted.vue';
import APIPayment from '@app/services/API/Payment';

export default {
  components: { StripeElementsSplitted },
  props: {
    paymentMethod: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    stripeError: null,
    stripeIsCompleted: false,
  }),
  methods: {
    handle() {
      if (!this.stripeIsCompleted) {
        return;
      }

      this.isLoading = true;
      this.$refs.card.createPaymentMethod()
        .then((res) => APIPayment.updatePaymentMethod(
          this.paymentMethod.id,
          { payment_method: res.paymentMethod.id },
        ))
        .then(({ data }) => {
          this.$showMessage.success();
          this.$emit('success', data);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <form class="box" @submit.prevent="handle">
    <h1 class="title is-4">
      Mettre à jour votre carte bancaire
    </h1>
    <b-field
      label="Renseignez votre carte bancaire"
      :type="{'is-danger': stripeError && stripeError.message}"
      :message="stripeError && stripeError.message"
    >
      <StripeElementsSplitted
        ref="card"
        @error="stripeError = $event"
        @complete="stripeIsCompleted = $event"
      />
    </b-field>
    <div class="buttons">
      <b-button
        type="is-primary"
        native-type="submit"
        :loading="isLoading"
        expanded>
        Modifier
      </b-button>
    </div>
  </form>
</template>
