<script>
import features from '@app/assets/data/features.json';

export default {
  props: {
    hasCta: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      features,
    };
  },
};
</script>

<template>
  <div class="lfcomp columns">
    <div class="column">
      <RenderlessToggle #default="{ isActive, toggleActive }" :length="features.PERCENT.length">
        <ul class="panel has-background-white">
          <li class="panel-block px-6 pt-6 pb-10">
            <div class="w-full">
              <h2 class="is-size-4 has-text-weight-bold w-full">
                <span class="is-block is-size-7 has-text-weight-normal">Pack</span>
                DÉCOUVERTE
              </h2>
              <p class="w-full">
                <span class="mb-4 has-text-success">Gratuit</span>
                <br>
                <span class="mb-4 has-text-info">Sans engagement</span>
              </p>
            </div>
          </li>
          <li v-for="(f, i) in features.PERCENT" :key="i" class="lfcomp_feature panel-block">
            <a class="lfcomp_feature_link w-full" :href="`#_f${i}`" @click.prevent="toggleActive(i)">
              <span class="lfcomp_feature_name">
                <b-icon
                  class="is-size-6 mr-4"
                  :class="f.icon_color || (f.included ? 'has-text-primary' : 'has-text-danger')"
                  :icon="f.icon || (f.included ? 'check-circle' : 'times')"
                />
                <span v-html="f.text" />
              </span>
              <b-icon class="has-text-info is-size-8" icon="plus" />
            </a>
            <div
              v-show="isActive === i"
              :id="`_f${i}`"
              class="mt-4 p-4 w-full has-background-light is-size-7"
            >
              {{ f.description }}
            </div>
          </li>
          <li v-if="hasCta" class="panel-block p-6">
            <b-button tag="router-link" type="is-primary" size="is-medium" to="/inscription" outlined expanded>
              Démarrez gratuitement
            </b-button>
          </li>
        </ul>
      </RenderlessToggle>
    </div>
    <div class="column">
      <RenderlessToggle #default="{ isActive, toggleActive }" :length="features.PRO.length">
        <ul class="panel has-background-white">
          <li class="panel-block p-6">
            <div class="w-full">
              <h2 class="is-size-4 has-text-weight-bold w-full">
                <span class="is-block is-size-7 has-text-weight-normal">Pack</span>
                PRO
              </h2>
              <p class="w-full">
                <span class="mb-4 has-text-success">
                  <span class="is-size-4 has-text-weight-bold">
                    {{ $constants.PRICES.MONTHLY.PRO }} &euro;
                  </span> TTC / mois
                </span>
                <br>
                <span class="mb-4 has-text-info">Sans engagement</span>
              </p>
            </div>
          </li>
          <li v-for="(f, i) in features.PRO" :key="i" class="lfcomp_feature panel-block">
            <a class="lfcomp_feature_link w-full" :href="`#_f${i}`" @click.prevent="toggleActive(i)">
              <span class="lfcomp_feature_name">
                <b-icon
                  class="is-size-6 mr-4"
                  :class="f.icon_color || (f.included ? 'has-text-primary' : 'has-text-danger')"
                  :icon="f.icon || (f.included ? 'check-circle' : 'times')"
                />
                <span v-html="f.text" />
              </span>
              <b-icon class="has-text-info is-size-8" icon="plus" />
            </a>
            <div
              v-show="isActive === i"
              :id="`_f${i}`"
              class="mt-4 p-4 w-full has-background-light is-size-7"
            >
              {{ f.description }}
            </div>
          </li>
          <li v-if="hasCta" class="panel-block p-6">
            <b-button tag="router-link" type="is-primary" size="is-medium" to="/inscription" expanded>
              Commencez dès maintenant
            </b-button>
          </li>
        </ul>
      </RenderlessToggle>
    </div>
    <div class="column">
      <RenderlessToggle #default="{ isActive, toggleActive }" :length="features.EXPERT.length">
        <ul class="panel has-background-white">
          <li class="panel-block p-6">
            <div class="w-full">
              <h2 class="is-size-4 has-text-weight-bold w-full">
                <span class="is-block is-size-7 has-text-weight-normal">Pack</span>
                EXPERT
              </h2>
              <p class="w-full">
                <span class="mb-4 has-text-success">
                  <span class="is-size-4 has-text-weight-bold">
                    {{ $constants.PRICES.MONTHLY.EXPERT }} &euro;
                  </span> TTC / mois
                  <strong>(au lieu de {{ $constants.PRICES.MONTHLY.EXPERT_REAL }} &euro;)</strong>
                </span>
                <br>
                <span class="mb-4 has-text-info">Sans engagement</span>
              </p>
            </div>
          </li>
          <li v-for="(f, i) in features.EXPERT" :key="i" class="lfcomp_feature panel-block">
            <a class="lfcomp_feature_link w-full" :href="`#_f${i}`" @click.prevent="toggleActive(i)">
              <span class="lfcomp_feature_name">
                <b-icon
                  class="is-size-6 mr-4"
                  :class="f.icon_color || (f.included ? 'has-text-primary' : 'has-text-danger')"
                  :icon="f.icon || (f.included ? 'check-circle' : 'times')"
                />
                <span v-html="f.text" />
              </span>
              <b-icon class="has-text-info is-size-8" icon="plus" />
            </a>
            <div
              v-show="isActive === i"
              :id="`_f${i}`"
              class="mt-4 p-4 w-full has-background-light is-size-7"
            >
              {{ f.description }}
            </div>
          </li>
          <li v-if="hasCta" class="panel-block p-6">
            <b-button tag="router-link" type="is-primary" size="is-medium" to="/inscription" expanded>
              Commencez dès maintenant
            </b-button>
          </li>
        </ul>
      </RenderlessToggle>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.lfcomp {
  &_feature {
    flex-direction: column;

    &_link,
    &_name {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &_link {
      color: $theme_color_black;
      &:hover {
        color: $theme_color_primary_hover;
      }
    }
  }

  @include mobile {
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>
