<script>
import debounce from 'lodash/debounce';
import Stripe from '@shared/services/Stripe';
import utilsMixin from '@shared/mixins/utils';
import StripeElementsSplitted from '@shared/components/StripeElementsSplitted.vue';
import { setTimeoutPromise } from '@shared/services/Utils';
import APIPayment from '@app/services/API/Payment';
import trainingsMixin from '@app/mixins/trainings';
import ListFeatures from '@app/components/ListFeatures.vue';
import UpdatePaymentMethod from '@app/components/UpdatePaymentMethod.vue';
import CancelSubscriptionForm from '@app/components/CancelSubscriptionForm.vue';

const WEBHOOK_TIMEOUT = 3000;

export default {
  i18n: {
    messages: {
      fr: {
        messages: {
          forbidden_trainings: 'Vous avez des formations en statut "<strong>caché</strong>" ou "<strong>non répertorié</strong>". Ces statuts n\'étant pas disponibles pour le Pack DÉCOUVERTE, veuillez d\'abord supprimer ou publier les formations concernées.',
          forbidden_training_settings: '<strong>Vos paramètres actuels concernant les logs et temps de connexion</strong> (vérificateur d\'activité et comportement à adopter au changement d\'onglet) ne sont pas disponibles pour le Pack DÉCOUVERTE. Veuillez désactiver ces paramétrages dans <strong>Formations > Paramètres</strong>.',
          too_many_trainings_for_plan: 'Vous avez <strong>plus de 3 formations publiées</strong>, ce qui correspond au maximum de formations disponibles sur le pack DÉCOUVERTE. Veuillez d\'abord supprimer ou mettre en brouillon les formations supplémentaires.',
        },
      },
    },
  },
  mixins: [trainingsMixin, utilsMixin],
  components: {
    ListFeatures,
    StripeElementsSplitted,
    UpdatePaymentMethod,
  },
  head: {
    title: 'Abonnement',
  },
  data() {
    return {
      isLoading: false,
      isFetching: false,
      isChangePaymentMethodOpen: false,
      stripeError: null,
      stripeIsCompleted: false,
      frequency: 'YEARLY',
      plan: 'PRO',
      discountIsChecking: false,
      discount: '',
      discountData: null,
      subscription: null,
      paymentMethods: null,
    };
  },
  computed: {
    authUser() {
      return this.$store.getters['auth/user'];
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
    hasProPlan() {
      return this.$store.getters['auth/hasProPlan'];
    },
    hasPerkPlan() {
      return this.$store.getters['auth/hasPerkPlan'];
    },
    hasPercentPlan() {
      return this.$store.getters['auth/hasPercentPlan'];
    },
    isNotPercentPlan() {
      return this.$store.getters['auth/isNotPercentPlan'];
    },
    subscriptionPlan() {
      if (!this.subscription.store_subscription) {
        return null;
      }

      const plan = this.subscription.store_subscription.items.find((v) => v.type !== 'USER_ACCOUNT');

      return plan;
    },
    subscriptionFrequency() {
      if (!this.subscription.store_subscription) {
        return null;
      }

      return this.subscription.store_subscription.frequency;
    },
    subscriptionStartDate() {
      if (!this.subscription.store_subscription) {
        return null;
      }

      const subscription = this.subscription.store_subscription.items.slice();
      subscription.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      return subscription[0].created_at;
    },
    paymentMethodExp() {
      let month = this.paymentMethods[0].card_exp_month;
      const year = this.paymentMethods[0].card_exp_year;

      if (month == null || year == null) {
        return '';
      }

      month = month > 9 ? month : `0${month}`;

      return `${month} / ${year.toString().substring(2)}`;
    },
    price() {
      return this.$constants.PRICES[this.frequency][this.plan];
    },
    hasValidNewDiscount() {
      return this.discountData && ('code' in this.discountData);
    },
    hasDiscountOnSubscription() {
      if (!this.subscriptionStartDate) {
        return false;
      }

      const data = this.subscription.additional_data;

      return (data.percent_off > 0 || data.amount_off > 0);
    },
    cantSubscribe() {
      return (
        (this.subscriptionPlan && this.subscriptionPlan.type === this.plan)
        || (!this.stripeIsCompleted && !this.paymentMethods.length)
        || this.discountIsChecking
      );
    },
  },
  methods: {
    loadData() {
      this.isFetching = true;
      return Promise.all([
        this.loadSubscriptions(),
        this.loadPaymentMethods(),
      ])
        .finally(() => {
          this.isFetching = false;

          if (this.subscriptionFrequency) {
            this.frequency = this.subscriptionFrequency;
          }
        });
    },
    loadSubscriptions() {
      return APIPayment.getSubscriptions()
        .then(({ data }) => (this.subscription = data));
    },
    loadPaymentMethods() {
      return APIPayment.getPaymentMethods()
        .then(({ data }) => (this.paymentMethods = data));
    },
    onPaymentMethodChange(paymentMethods) {
      this.paymentMethods = paymentMethods;
      this.isChangePaymentMethodOpen = false;
    },
    handle() {
      const needsStripeCompleted = !this.paymentMethods.length && !this.stripeIsCompleted;

      if (needsStripeCompleted) {
        return;
      }

      if (this.subscriptionStartDate) {
        const loader = this.$buefy.loading.open();
        APIPayment.getSubscriptionProration({
          type: this.plan,
          frequency: this.subscriptionFrequency,
          promo_code: this.discount || undefined,
        })
          .then(({ data }) => {
            let price;
            const subscriptionData = this.subscription.additional_data;

            if ((this.hasValidNewDiscount || this.hasDiscountOnSubscription) && this.plan !== 'EXPERT') {
              const discountData = this.hasValidNewDiscount
                ? this.discountData
                : subscriptionData;

              price = discountData.percent_off > 0
                ? (this.price * (1 - discountData.percent_off / 100))
                : (this.price - discountData.amount_off);

              price = utilsMixin.filters.formatPrice(price);
            } else {
              price = `${this.price} €`;
            }

            let prorataMessage;
            const currentPlanName = this.$t(`packs.${this.authStore.plan}`);

            if (data.next_payment_date) {
              prorataMessage = `
                <p>
                  Pour passer au nouveau Pack, vous serez facturé immédiatement de
                  ${utilsMixin.filters.formatPrice(data.prorated_amount)} TTC
                  (prorata de votre période de facturation en cours),
                  puis de ${price} TTC à chaque date anniversaire de votre abonnement
                  (à partir du ${this.$moment.utc(data.next_payment_date).local().format('DD/MM/YY')})
                </p>
              `.trim();
            } else {
              prorataMessage = `
                <p>
                  En changeant de Pack, vous perdrez immédiatement
                  <strong>tous vos avantages spécifiques au ${currentPlanName}</strong>.
                </p>
                <p>
                  Votre abonnement passera à ${price} à partir du
                  ${this.$moment.utc(subscriptionData.next_payment_date).local().format('DD/MM/YY')},
                  date de votre prochaine facture.
                </p>
              `.trim();
            }

            this.$buefy.dialog.confirm({
              message: `
                <div class="content">
                  ${prorataMessage}
                </div>
              `,
              confirmText: 'Souscrire',
              focusOn: 'cancel',
              onConfirm: () => this.subscribe(),
            });
          })
          .finally(() => loader.close());

        return;
      }

      this.subscribe();
    },
    subscribe() {
      let promise;
      const loader = this.$buefy.loading.open();
      this.isLoading = true;

      if (this.paymentMethods.length) {
        promise = APIPayment.subscribe({
          type: this.plan,
          frequency: this.frequency,
          promo_code: this.discount || undefined,
        });
      } else {
        promise = this.$refs.card.createPaymentMethod()
          .then((res) => APIPayment.subscribe({
            type: this.plan,
            frequency: this.frequency,
            payment_method: res.paymentMethod.id,
            promo_code: this.discount || undefined,
          }));
      }

      return promise.then(this.handleSubscription)
        .then(() => setTimeoutPromise(
          () => Promise.all([this.loadData(), this.$store.dispatch('auth/fetch')]),
          WEBHOOK_TIMEOUT,
        ))
        .then(() => {
          this.$showMessage.success();
          this.$scrollTo(this.$el);
        })
        .finally(() => {
          this.isLoading = false;
          loader.close();
        });
    },
    handleSubscription({
      data: {
        user, additional_data, store_subscription, subscription,
      },
    }) {
      this.$store.commit('auth/update', user);

      const { latest_invoice } = subscription;
      const { payment_intent } = latest_invoice;

      if (payment_intent) {
        const { client_secret, status } = payment_intent;

        if (status === 'requires_action' || status === 'requires_confirmation') {
          let confirmCardPayment;

          if (this.paymentMethods.length) {
            confirmCardPayment = Stripe.init(this.$env.stripePK)
              .then(() => Stripe.confirmCardPayment(client_secret, {
                payment_method: payment_intent.payment_method,
              }));
          } else {
            confirmCardPayment = this.$refs.card.confirmCardPayment(client_secret);
          }

          return confirmCardPayment.then(() => {
            this.$store.commit('auth/update', user);
            this.subscription = { additional_data, store_subscription };
          });
        }
      }

      this.$store.commit('auth/update', user);
      this.subscription = { additional_data, store_subscription };
      return null;
    },
    unsubscribeStorePlan() {
      if (!this.subscriptionPlan) {
        return;
      }

      const trainings = this.$store.state.trainings.list;

      if (!trainings) {
        const loader = this.$buefy.loading.open();
        this.$store.dispatch('trainings/fetch')
          .then((trainings) => this.confirmUnsubscribeStorePlan(trainings.data))
          .finally(() => loader.close());

        return;
      }

      this.confirmUnsubscribeStorePlan(trainings.data);
    },
    confirmUnsubscribeStorePlan(trainings) {
      const hasForbiddenTrainings = trainings.some((v) => ['UNLISTED', 'HIDDEN'].includes(v.status));

      if (hasForbiddenTrainings) {
        this.$buefy.dialog.alert(this.$t('messages.forbidden_trainings'));
        return;
      }

      const nbPublishedTrainings = trainings.filter((v) => v.status !== 'DRAFT').length;

      if (nbPublishedTrainings > 3) {
        this.$buefy.dialog.alert(this.$t('messages.too_many_trainings_for_plan'));
        return;
      }

      const trainingOptions = this.authStore.feature_options;
      if (trainingOptions && trainingOptions.customer_session
          && (trainingOptions.customer_session.inactivity_timeout != null
              || trainingOptions.customer_session.disabled_on_tab_quit)) {
        this.$buefy.dialog.alert(this.$t('messages.forbidden_training_settings'));
        return;
      }

      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: `
          La résiliation de votre abonnement sera immédiate et vous perdrez toutes les fonctionnalités
          du ${this.$t(`packs.${this.subscriptionPlan.type}`)} dès maintenant.
        `,
        focusOn: 'cancel',
        onConfirm: () => {
          const modal = this.$buefy.modal.open({
            parent: this,
            component: CancelSubscriptionForm,
            hasModalCard: true,
            props: {
              subscriptionPlan: this.subscriptionPlan,
            },
            events: {
              confirm: ({ password, cancel_reason, cancel_additional_text } = {}) => {
                const loader = this.$buefy.loading.open();
                APIPayment.unsubscribe({
                  password,
                  cancel_reason,
                  cancel_additional_text,
                  subscriptionId: this.subscription.store_subscription.id,
                  itemId: this.subscriptionPlan.id,
                })
                  .then(({ data: { user, additional_data, store_subscription } }) => {
                    this.$store.commit('auth/update', user);
                    this.subscription = { additional_data, store_subscription };
                    return setTimeoutPromise(() => Promise.all([
                      this.loadData(),
                      this.$store.dispatch('auth/fetch'),
                    ]), WEBHOOK_TIMEOUT);
                  })
                  .finally(() => {
                    loader.close();
                    modal.close();
                  });
              },
            },
          });
        },
      });
    },
    checkDiscount: debounce(function() {
      if (!this.discount) {
        this.discountData = null;
        return;
      }

      this.discountIsChecking = true;
      APIPayment.checkDiscount({ code: this.discount, type: this.plan })
        .then(({ data }) => (this.discountData = data))
        .finally(() => (this.discountIsChecking = false));
    }, 250),
    switchPlan(plan) {
      this.plan = plan;

      if (this.plan === 'EXPERT') {
        this.discount = '';
      }

      this.checkDiscount();
    },
    switchFrequency(frequency) {
      this.frequency = frequency;

      this.checkDiscount();
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<template>
  <div class="pcomp">
    <h2 class="title is-4 is-size-5-mobile mb-5 has-text-centered">
      Découvrez les avantages et les possibilités de Teachizy
    </h2>
    <p class="is-size-5 is-size-7-mobile mb-10 has-text-centered">
      Vous êtes actuellement sur le Pack
      <strong class="tdecoration-underline" v-t="`plans.${authStore.plan}`" />
    </p>

    <div class="tabs mb-10">
      <ul>
        <router-link tag="li" :to="{query: {}}" exact-active-class="is-active">
          <a >Nos offres</a>
        </router-link>
        <router-link tag="li" :to="{query: {onglet: 'vous'}}" exact-active-class="is-active">
          <a >Votre abonnement</a>
        </router-link>
      </ul>
    </div>

    <AppFeatureWrapper v-if="$route.query.onglet == 'vous'" tag="section">
      <template #title>
        Gérez votre abonnement
      </template>

      <template #content>
        <p>
          Pour bénéficier des fonctionnalités avancées de Teachizy,
          nous vous proposons un <strong>Pack PRO</strong> ou un <strong>Pack EXPERT</strong>,
          tous les deux <strong>sans engagement</strong>.
        </p>
        <p>
          Nous ne stockons aucune carte bancaire dans notre système,
          les paiements sont traités par <strong>Stripe</strong>,
          un service <strong>sécurisé</strong> de paiement en ligne.
        </p>
        <p>
          Le <strong>Pack DÉCOUVERTE</strong>, qui est gratuit et sans engagement,
          restera disponible en cas de besoin.
        </p>
      </template>

      <template #form>
        <div v-if="hasPerkPlan" class="box is-warning">
          <div class="columns is-multiline is-vcentered">
            <p class="column">
              Pour débloquer <strong>TOUTES les fonctionnalités en illimité</strong>,
              vous pouvez souscrire au pack PRO ou EXPERT
            </p>
            <div class="column is-narrow">
              <b-button type="is-warning" @click="$scrollTo($refs.payment_form)">
                Débloquer <b-icon class="has-text-black ml-1" icon="gem" />
              </b-button>
            </div>
          </div>
        </div>

        <b-skeleton v-if="isFetching" height="500" active />
        <div v-else>
          <section class="is-relative">
            <div class="box">
              <header class="columns">
                <div class="column">
                  <h2 class="title is-5 mb-0">
                    Votre abonnement
                  </h2>
                </div>
                <div class="column is-narrow">
                  <p class="tag is-primary is-medium" v-t="`packs.${authStore.plan}`" />
                  <p
                    v-if="subscription.store_subscription"
                    class="tag is-outlined is-medium ml-3"
                    v-t="`plan_frequencies.${subscription.store_subscription.frequency}`"
                  />
                </div>
              </header>
              <template v-if="subscription.store_subscription">
                <p
                  v-if="subscription.store_subscription.last_payment_failed"
                  class="notification is-danger mt-5 mb-0">
                  Dernier paiement échoué
                </p>
                <table class="table is-bordered mt-5">
                  <tr>
                    <th>
                      Produit
                    </th>
                    <th class="has-text-right">
                      Prix
                    </th>
                  </tr>
                  <tr v-for="item in subscription.store_subscription.items" :key="item.id">
                    <td>
                      <span v-t="`subscription_items.${item.type}`" />
                      <template v-if="item.quantity > 1">
                        (x{{ item.quantity }})
                      </template>
                    </td>
                    <td class="has-text-right" width="128">
                      {{ item.amount | formatPrice }}
                    </td>
                  </tr>
                </table>
                <p class="columns">
                  <span class="column">
                    <strong>Démarré le : </strong>
                    {{ subscription.store_subscription.created_at | momentFromUTC | moment('DD/MM/YY') }}
                  </span>
                  <span class="column is-narrow break-words">
                    <strong>Prochain paiement : </strong>
                    {{ subscription.additional_data.next_payment_amount | formatPrice }} •
                    {{ subscription.additional_data.next_payment_date | momentFromUTC | moment('[le] DD/MM/YY') }}
                  </span>
                </p>
              </template>
            </div>

            <p v-if="subscriptionPlan" class="has-text-right mb-5">
              <b-button
                class="has-text-danger"
                type="is-text"
                size="is-small"
                @click="unsubscribeStorePlan"
              >
                Résilier l'abonnement <span v-t="`plans.${subscriptionPlan.type}`" />
              </b-button>
            </p>
          </section>

          <div v-if="paymentMethods.length" class="box mt-5">
            <h2 class="title is-5">
              Votre carte bancaire
            </h2>
            <div class="columns is-size-5">
              <div class="column is-9">
                <b-field label="Numéro">
                  <b-input :value="`XXXX XXXX XXXX ${paymentMethods[0].card_last4}`" readonly />
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Expiration">
                  <b-input :value="paymentMethodExp" readonly />
                </b-field>
              </div>
            </div>
            <p class="has-text-right">
              <b-button
                type="is-text"
                class="tdecoration-underline px-0"
                @click="isChangePaymentMethodOpen = true">
                Changer ma carte
              </b-button>
            </p>
            <b-modal width="640px" :active.sync="isChangePaymentMethodOpen">
              <UpdatePaymentMethod
                :paymentMethod="paymentMethods[0]"
                @success="onPaymentMethodChange"
              />
            </b-modal>
          </div>

          <form
            ref="payment_form"
            class="box mt-5"
            @submit.prevent="dataIsValid(isLoading) && handle()">
            <h3 class="title is-5">
              <template v-if="subscriptionStartDate">
                Changer d'offre
              </template>
              <template v-else>
                Sélectionnez une offre
              </template>
            </h3>
            <template v-if="!subscriptionFrequency">
              <b-field label="Choisissez votre rythme de facturation">
                <b-radio
                    name="frequency"
                    native-value="MONTHLY"
                    :value="frequency"
                    @input="switchFrequency('MONTHLY')">
                  Mensuel
                </b-radio>
                <b-radio
                  name="frequency"
                  native-value="YEARLY"
                  :value="frequency"
                  @input="switchFrequency('YEARLY')">
                  Annuel <span class="tag is-outlined ml-3">-17%</span>
                </b-radio>
              </b-field>
              <hr>
            </template>
            <b-field label="Choisissez votre pack">
              <div class="columns">
                <div class="column pr-1">
                  <button
                    class="box p-3 w-full outline-none has-text-left is-size-4 is-size-5-mobile cursor-pointer"
                    :class="{
                      'border-0': plan != 'PRO',
                      'is-primary': plan == 'PRO'
                    }"
                    type="button"
                    @click="switchPlan('PRO')"
                  >
                    <strong class="is-block is-size-5 mb-3">
                      Pack PRO
                    </strong>
                    {{ $constants.PRICES[frequency].PRO }} &euro;
                    <span class="is-size-6">
                      TTC / <span v-t="`plan_frequencies.PER_${frequency}`" />
                    </span>
                    <span class="is-block mt-2 is-size-7 is-size-8-mobile">
                      sans engagement
                      <br>
                      sans surcoût
                    </span>
                  </button>
                </div>
                <div class="column pl-1">
                  <button
                    class="box p-3 w-full outline-none has-text-left is-size-4 is-size-5-mobile cursor-pointer"
                    :class="{
                      'border-0': plan != 'EXPERT',
                      'is-primary': plan == 'EXPERT'
                    }"
                    type="button"
                    @click="switchPlan('EXPERT')"
                  >
                    <strong class="is-block is-size-5 mb-3">
                      Pack EXPERT
                    </strong>
                    {{ $constants.PRICES[frequency].EXPERT }} &euro;
                    <span class="is-size-6">
                      TTC / <span v-t="`plan_frequencies.PER_${frequency}`" />
                    </span>
                    <span class="is-block mt-2 is-size-7 is-size-8-mobile has-text-primary has-text-weight-bold">
                      Au lieu de {{ $constants.PRICES[frequency].EXPERT_REAL }} &euro;
                      <br>
                      Réduction de -24%
                    </span>
                  </button>
                </div>
              </div>
            </b-field>
            <div
              v-if="frequency == 'YEARLY' && plan != 'EXPERT'"
              class="field is-block has-text-weight-bold is-size-6 is-size-7-mobile has-text-primary">
              Au lieu de {{ $constants.PRICES.MONTHLY[plan] * 12 }} &euro; / an, soit 2 mois offerts !
            </div>
            <div v-if="hasDiscountOnSubscription && plan != 'EXPERT'" class="notification">
              Votre abonnement bénéficie actuellement d'une réduction,
              le prix de ce pack vous revient donc à
              <template v-if="subscription.additional_data.amount_off > 0">
                {{ (price - subscription.additional_data.amount_off) | formatPrice }}
                (-{{ subscription.additional_data.amount_off | formatPrice }})
              </template>
              <template v-else-if="subscription.additional_data.percent_off > 0">
                {{ (price * (1 - subscription.additional_data.percent_off / 100)) | formatPrice }}
                (-{{ subscription.additional_data.percent_off }}%)
              </template>
            </div>
            <b-field
              v-if="!hasDiscountOnSubscription"
              :addons="false">
              <template #label>
                Code promo
                <template v-if="plan == 'EXPERT'">
                  (cette offre est déjà en promotion)
                </template>
              </template>
              <b-input
                v-model.trim="discount"
                :loading="discountIsChecking"
                maxlength="32"
                :has-counter="false"
                :disabled="plan == 'EXPERT'"
                @input="checkDiscount"
              />
              <p
                v-if="discountData"
                class="mt-3 has-text-weight-bold"
                :class="!hasValidNewDiscount ? 'has-text-danger' : 'has-text-success'"
              >
                <template v-if="discountData.amount_off > 0">
                  {{ (price - discountData.amount_off) | formatPrice }}
                  ({{ discountData.amount_off | formatPrice }} de réduction)
                </template>
                <template v-else-if="discountData.percent_off > 0">
                  {{ (price * (1 - discountData.percent_off / 100)) | formatPrice }}
                  ({{ discountData.percent_off }} % de réduction)
                </template>
                <template v-else-if="!hasValidNewDiscount">
                  Ce code promo n'est pas utilisable.
                </template>
              </p>
            </b-field>
            <b-field
              v-if="!paymentMethods.length"
              label="Renseignez votre carte bancaire"
              :type="{'is-danger': stripeError && stripeError.message}"
              :message="stripeError && stripeError.message"
            >
              <StripeElementsSplitted
                ref="card"
                @error="stripeError = $event"
                @complete="stripeIsCompleted = $event"
              />
            </b-field>
            <div class="mt-5">
              <p
                v-if="subscriptionPlan && subscriptionPlan.type == plan"
                class="field has-text-weight-bold has-text-centered">
                C'est votre abonnement actuel.
              </p>
              <template v-else>
                <b-field class="has-text-right">
                  <b-button
                    type="is-primary"
                    native-type="submit"
                    :loading="isLoading"
                    :disabled="cantSubscribe"
                    expanded
                  >
                    <span v-if="authUser.stripe_subscription_customer_id">
                      S'abonner au Pack {{ plan }}
                    </span>
                    <span v-else>
                      Souscrire au Pack {{ plan }}
                    </span>
                  </b-button>
                </b-field>
                <p class="is-size-8 has-text-centered has-text-primary">
                  <b-icon icon="shield-alt" />
                  Paiement sécurisé par SSL
                </p>
              </template>
            </div>
          </form>
        </div>
      </template>
    </AppFeatureWrapper>

    <section v-else>
      <ListFeatures :has-cta="false" />
      <div v-if="hasPercentPlan" class="content has-text-centered">
        <p>
          <b-button
            tag="router-link"
            type="is-primary"
            size="is-large"
            :to="{query: {onglet: 'vous'}}">
            Débloquer toutes les fonctionnalités !
          </b-button>
        </p>
        <p>Pour profiter à 100% de Teachizy</p>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.pcomp {
}
</style>
