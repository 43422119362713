<script>
export default {
  props: {
    subscriptionPlan: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    cancelData: {
      password: '',
      cancel_reason: '',
      cancel_additional_text: '',
    },
  }),
};
</script>

<template>
  <form class="modal-card" @submit.prevent="$emit('confirm', cancelData)" autocomplete="off">
    <div class="modal-card-head">
      <h2 class="modal-card-title">
        Attention !
      </h2>
    </div>

    <div class="modal-card-body">
      <div class="content">
        <h3>
          Vous perdrez la possibilité :
        </h3>
        <ul>
          <li>de pouvoir <strong>configurer le délai d'accès</strong> des leçons</li>
          <li>de créer des <strong>formations et codes promo</strong> en illimité</li>
          <li>d'avoir <strong>autant d'apprenants inscrits que vous le voulez</strong></li>
          <li>de récupérer les <strong>ventes perdues</strong> (paniers abandonnées)</li>
          <li>de personnaliser tous vos emails marketing</li>
          <li>d'avoir un <strong>nom de domaine personnalisé</strong></li>
          <li>d'offrir <strong>PayPal</strong> comme option de paiement</li>
          <li>de pouvoir proposer <strong>des facilités de paiement</strong></li>
          <li>de confier <strong>des tâches</strong> à vos apprenants</li>
        </ul>
        <p class="has-text-weight-bold">Et plus encore...</p>
      </div>
      <div class="field">
        Pour confirmer la résiliation de votre abonnement au pack
        <span v-t="`plans.${this.subscriptionPlan.type}`" />,
        <br>
        veuillez saisir votre mot de passe.
      </div>
      <b-field label="Mot de passe" label-for="password">
        <b-input type="password" id="password" v-model="cancelData.password" required />
      </b-field>
      <b-field label="Choisissez la raison de votre résiliation" label-for="cancel_reason">
        <b-select id="cancel_reason" v-model="cancelData.cancel_reason" required expanded>
          <option value="NOT_ENOUGH_SALES" v-t="'quit_reasons.NOT_ENOUGH_SALES'" />
          <option value="NOT_ENOUGH_TIME" v-t="'quit_reasons.NOT_ENOUGH_TIME'" />
          <option value="ALTERNATIVE_FOUNDED" v-t="'quit_reasons.ALTERNATIVE_FOUNDED'" />
          <option value="MISSING_FEATURES" v-t="'quit_reasons.MISSING_FEATURES'" />
          <option value="PRODUCT_NOT_BUILT" v-t="'quit_reasons.PRODUCT_NOT_BUILT'" />
          <option value="TOO_EXPENSIVE" v-t="'quit_reasons.TOO_EXPENSIVE'" />
          <option value="TOO_HARD" v-t="'quit_reasons.TOO_HARD'" />
          <option value="OTHER" v-t="'quit_reasons.OTHER'" />
        </b-select>
      </b-field>
      <b-field label="Précisez les raisons de votre résiliation" label-for="cancel_additional_text">
        <b-input
          type="textarea"
          id="cancel_additional_text"
          autocomplete="off"
          v-model="cancelData.cancel_additional_text"
          required
        />
      </b-field>
    </div>

    <div class="modal-card-foot is-justify-content-flex-end btop-grey">
      <b-button type="is-text" @click="$emit('close')">
        Annuler
      </b-button>
      <b-button type="is-primary" native-type="submit">
        Résilier quand même
      </b-button>
    </div>
  </form>
</template>
